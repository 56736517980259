import { defineElement, BaseElement } from '@webtides/element-js';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

class Header extends BaseElement {
    properties() {
        return {
            menuOpen: false,
            subMenuOpen: false,
        };
    }

    watch() {
        return {
            menuOpen: (open) => {
                this.$refs.navigation.classList.toggle('is-open', open);
                this.$refs.closeBtn.classList.toggle('is-visible', open);
                this.$refs.openBtn.classList.toggle('not-visible', open);

                if (open) {
                    disableBodyScroll(this.$refs.navigation);
                } else {
                    enableBodyScroll(this.$refs.navigation);
                }
            },
            subMenuOpen: (open) => {
                this.$refs.subMenu.classList.toggle('hidden');
                this.$refs.dropDown.setAttribute('icon', this.subMenuOpen ? 'arrowUp' : 'arrowDown');
            },
        };
    }

    events() {
        return {
            '[ref=menuBtn]': {
                click: () => {
                    this.menuOpen = !this.menuOpen;
                },
            },
            '[ref=dropDown]': {
                click: () => {
                    this.subMenuOpen = !this.subMenuOpen;
                },
            },
        };
    }
}

defineElement('header-area', Header);
